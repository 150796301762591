import React, { Component, useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from "@headlessui/react";
import axios from 'axios';
import { MdEdit } from 'react-icons/md';

export default function AddCustomerNotes() {

    let [isOpen, setIsOpen] = useState(false);

    //add new
    function closeModal() {
        window.location.reload();
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    const [notes, setNotes] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [city, setCity] = useState('');
    const [date, setDate] = useState('');
    const [recentDownload, setRecentDownload] = useState('');
    const [recentDownloadStaff, setRecentDownloadStaff] = useState('');
    const [hidden, setHidden] = useState(false);

    const updateData = () => {
        if (customerName.length && city.length) {
            axios.post(`${process.env.REACT_APP_API_URL}inventoryManagement/save-customer-name`,
                {
                    customer_name: customerName,
                    city: city,
                    notes: notes,
                    date: date,
                    recent_download: recentDownload,
                    recent_download_staff: recentDownloadStaff,
                    is_hidden: hidden
                    // next_download: nextDownload,
                    // next_download_staff: nextDownloadStaff
                }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("apEnergyAdminToken")}`
                }
            }).then(res => {
                setCustomerName('');
                setCity('');
                setDate('');
                setNotes('');
                setRecentDownload('');
                setRecentDownloadStaff('');
                setHidden(false);
                setIsOpen(false);
                window.location.reload()
            }).catch(err => {
                console.log(err);
                alert('Something went wrong');
            })
        } else {
            alert('Enter Valid Customer Name and City');
        }
    }

    return (
        <>
            <div className="">
                <button
                    type="button"
                    onClick={openModal}
                    className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25"
                >
                    Add New Customer
                </button>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Update Admin Inventory Notes
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div class="grid gap-6 mb-6 lg:grid-cols-2">
                                            <div>
                                                <label
                                                    for="name"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Customer Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="notes"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                    placeholder="Customer Name"
                                                    value={customerName}
                                                    onChange={(e) => { setCustomerName(e.target.value) }}
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    for="name"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    City
                                                </label>
                                                <input
                                                    type="text"
                                                    id="notes"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                    placeholder="City"
                                                    value={city}
                                                    onChange={(e) => { setCity(e.target.value) }}
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    for="name"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Notes
                                                </label>
                                                <input
                                                    type="text"
                                                    id="notes"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                    placeholder="Notes"
                                                    value={notes}
                                                    onChange={(e) => { setNotes(e.target.value) }}
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    for="name"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Date Set/Staff
                                                </label>
                                                <input
                                                    type="text"
                                                    id="notes"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                    placeholder="Date"
                                                    value={date}
                                                    onChange={(e) => { setDate(e.target.value) }}
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    for="name"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Recent Download
                                                </label>
                                                <input
                                                    type="text"
                                                    id="notes"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                    placeholder="Recent Download"
                                                    value={recentDownload}
                                                    onChange={(e) => { setRecentDownload(e.target.value) }}
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    for="name"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Recent Download Staff
                                                </label>
                                                <input
                                                    type="text"
                                                    id="notes"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                                                    placeholder="Recent Download Staff"
                                                    value={recentDownloadStaff}
                                                    onChange={(e) => { setRecentDownloadStaff(e.target.value) }}
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    for="name"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Hide
                                                </label>
                                                <div className='flex gap-4'>
                                                    <input
                                                        type="checkbox"
                                                        checked={hidden}
                                                        class="bg-gray-50 border border-gray-300 p-2.5"
                                                        onChange={(e) => { setHidden(e.target.checked) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            type="submit"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white"
                                            // onClick={closeModal}
                                            onClick={updateData}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}